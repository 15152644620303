<template>
   <div class="print_footer_design_3" id="print_footer_design_3">
      <p v-if="shopNote" class="note">
         {{ shopNote }}
      </p>
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
   setup () {
      const store = useStore();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopNote: shop.note || false,
      });

      return {
         ...toRefs(state),
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_footer_design_3 {
   display: grid;
   gap: rem(5);
   padding: 1rem;
   margin-top: auto;
   font-size: 1rem;

   .note {
      color: var(--print-d3-clr);
   }
}
</style>