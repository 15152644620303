import globalJs from "./globalJs";

const global = {
   // ~~~~~~~~~~~~~> live image on selecting file
   liveImg : {
      mounted(input, binding) {
         if (input.type != 'file') return;
         if (!binding.value) return;

         input.addEventListener('change', function(e) {

            let output = el(binding.value) || false;
            if (!output) return;

            output.setAttribute('src', URL.createObjectURL(e.target.files[0]));
            output.addEventListener('load', function () {
               URL.revokeObjectURL(output.src) // free memory
            });
         });
      }
   },

   // ~~~~~~~~~~~~~> make focus on input
   focus: {
      mounted(el, binding) {
         if (binding.value != undefined) {
            if (!binding.value) return;
         }

         if (window.innerWidth >= 768) el.focus();
      }
   },

   // ~~~~~~~~~~~~~> when inserting value make it count for phone number
   countPhoneNumber: {
      mounted(el, binding) {
         let phoneNumberCount  = parseInt(binding.value);
         let input             = el;
         let span              = input.nextElementSibling;

         if (input.value.trim().length !== 0) span.innerHTML = phoneNumberCount - input.value.length;
      
         input.addEventListener('keyup', function() {
            span.innerHTML = phoneNumberCount - this.value.length;
      
            if (span.innerHTML < 0) span.innerHTML = 0;
            if (span.innerHTML > phoneNumberCount) span.innerHTML = phoneNumberCount;
         });

         input.addEventListener('blur', function() {
            if (input.value.trim().length === 0) span.innerHTML = phoneNumberCount;
         })
      },
   },

   // ~~~~~~~~~~~~~> global events on elements
   globalJs : {
      mounted() { globalJs() },
      updated() { globalJs() }
   },
};


export default global;
