<template>
   <div class="print_invoice_contact_details_8cm" id="print_invoice_contact_details_8cm">
      <!-- supplier  -->
      <table v-if="supplier" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_supplier') }}</th>
               <th>{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ supplier.SpName }}</td>
               <td>{{ supplier.SpPhone }}</td>
            </tr>
         </tbody>
      </table>

      <!-- customer  -->
      <table v-if="customer" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_client') }}</th>
               <th>{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ customer.CustName }}</td>
               <td>{{ customer.CustPhone }}</td>
            </tr>
         </tbody>
      </table>

      <!-- driver -->
      <table v-if="driver" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_driver_name') }}</th>
               <th>{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ driver.DName }}</td>
               <td>{{ driver.DPhone }}</td>
            </tr>
         </tbody>
      </table>

      <!-- delegates -->
      <table v-if="delegate" v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>{{ $t('lang_delegate_name') }}</th>
               <th>{{ $t('lang_phone') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{{ delegate.DelName }}</td>
               <td>{{ delegate.DelPhone }}</td>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
export default {
   props: {
      customer: { type: [Object, Boolean], default: false },
      supplier: { type: [Object, Boolean], default: false },
      driver:   { type: [Object, Boolean], default: false },
      delegate: { type: [Object, Boolean], default: false },
      invoice_type: String,
   },

   setup(props) {
      if (isEmpty(props.driver)) props.driver    = false;
      if (isEmpty(props.delegate)) props.delegate = false;
   }
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

#print_invoice_contact_details_8cm {
   table {
      td,th {
         font-size: rem(12);
      }
   }
}


</style>