import i18n from "@/language/i18n";
const t = i18n.global.t;

export default function globalJs() {
   //▬▬▬▬▬▬▬▬ show password icon
   if (el('[data-show-pwd-icon]')) {
      function showPwd() {
         let input = this.parentElement.querySelector('input');
         let attr  = input.getAttribute('type');

         if (attr == 'password') input.setAttribute('type', 'text');
         else input.setAttribute('type', 'password');
         
         this.classList.toggle('fa-eye-slash');
         this.classList.toggle('fa-eye');
      }
      el('[data-show-pwd-icon]').onclick = showPwd;
   };

   // ▬▬▬▬▬▬▬▬ cancel autocomplete in input
   document.querySelectorAll('input').forEach(input => {
      if (input.getAttribute('autocomplete') == 'off') return;
      input.setAttribute('autocomplete', 'off');
   });


   // ▬▬▬▬▬▬▬▬ tooltip
   const tooltip = el('[data-tooltip]');

   if (el('[tooltip-overflow]')) {
      el('[tooltip-overflow]', true).forEach(element => {
         element.onmousemove = function(e) {
            let el = this;
            if (!el.hasAttribute('tooltip-overflow')) return;
            
            tooltip.classList.remove('d-none');
            tooltip.style.transition = 'opacity .3s .05s ease';
            // tooltip.style.display    = 'block';
      
            let y             = el.getBoundingClientRect().top + document.documentElement.scrollTop;
            let x             = el.getBoundingClientRect().left + document.documentElement.scrollLeft;
            let ttWidth       = tooltip.getBoundingClientRect().width;
            let ttHeight      = tooltip.getBoundingClientRect().height;
            let text          = el.getAttribute('tt-text').trim();
            let direction     = el.getAttribute('tt-dir').trim();
      
            let isLight       = el.hasAttribute('tt-light');
            let isBold        = el.hasAttribute('tt-fw-bold');
            let bgClr         = el.getAttribute('tt-bg-clr');
            let textClr       = el.getAttribute('tt-text-clr');
            let isShadow      = el.hasAttribute('tt-shadow');
            let fs            = el.getAttribute('tt-fs');
            let windowWidth   = window.innerWidth;
            let elWidth       = el.getBoundingClientRect().width;
            let elHeight      = el.getBoundingClientRect().height;
            let tooltipMargin = 8;

            tooltip.innerHTML = t(text);

            if (direction == 'left') {
               tooltip.style.left      = `${x  - tooltipMargin + ttWidth * -1}px`;
               tooltip.style.top       = `${y + (elHeight / 2)}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(0%, -50%)`;
            }
            
            if (direction == 'right') {
               tooltip.style.right     = `${(windowWidth - x - elWidth - ttWidth - tooltipMargin)}px`;
               tooltip.style.top       = `${y + (elHeight / 2)}px`;
               tooltip.style.left      = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(0%, -50%)`;
            }
      
            if (direction == 'top') {
               tooltip.style.top       = `${(y - tooltipMargin )}px`;
               tooltip.style.left      = `${(x + (elWidth / 2))}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(-50%, -100%)`;
            }
      
            if (direction == 'bottom') {
               tooltip.style.top       = `${y + elHeight + tooltipMargin}px`;
               tooltip.style.left      = `${x + (elWidth / 2)}px`;
               tooltip.style.right     = `auto`;
               tooltip.style.bottom    = `auto`;
               tooltip.style.opacity   = `1`;
               tooltip.style.transform = `translate(-50%, 0%)`;
            }
      
            if (el.hasAttribute('tt-bg-clr')) tooltip.style.backgroundColor = bgClr;
            else tooltip.style.backgroundColor = 'black';
      
            if (el.hasAttribute('tt-text-clr')) tooltip.style.color = textClr;
            else tooltip.style.color = 'white';
      
            if (el.hasAttribute('tt-fs')) tooltip.style.fontSize = fs;
            else tooltip.style.fontSize = '1rem';
      
            if (el.hasAttribute('tt-fw-bold')) tooltip.style.fontWeight = 'bold';
            else tooltip.style.fontWeight = 'normal';
      
            if (isShadow) tooltip.classList.add('shadow')
            else tooltip.classList.remove('shadow')
      
            if (isLight) {
               tooltip.style.backgroundColor = 'white';
               tooltip.style.color = 'black';
            }
      
            if (isBold) tooltip.style.fontWeight = 'bold';
         };

         element.onmouseleave = function(e) {
            if (!tooltip.classList.contains('d-none')) tooltip.classList.add('d-none');

            tooltip.style.transition = 'none';
            tooltip.style.left       = 'auto';
            tooltip.style.right      = 'auto';
            tooltip.style.top        = 'auto';
            tooltip.style.bottom     = 'auto';
            tooltip.style.opacity    = 0;
         };
      });

      document.onmousemove = function(e) {
         let el     = e.target;
         let isHTML = el instanceof HTMLElement;

         if (!isHTML) return;

         if (!findParent(el, '[tooltip-overflow]')) {
            if (!tooltip.classList.contains('d-none')) tooltip.classList.add('d-none');
         }
      };
   }

   // ▬▬▬▬▬▬▬▬ make hover to moving icons when its hovered on his parent
   if (el('lord-icon')) {
      
      let movingIcons = el('lord-icon', true);
      const mouseoverEvent = new Event('mouseenter');

      movingIcons.forEach(icon => {
         let parent = icon.parentElement;

         parent.onmouseover = () => icon.dispatchEvent(mouseoverEvent);
      })

   }


   // ▬▬▬▬▬▬▬▬ when clicking to the box make radio checked
   if (el('.radioToCheckBox')) {
      
      el('.radioToCheckBox', true).forEach(el => {
         let parent = el.parentElement;
         let input  = parent.querySelector('input[type="radio"]');
         
         el.onclick = ()=> input.click();
      });

   }


   // ▬▬▬▬▬▬▬▬ adding image loader 
   // if (el('img')) {
   //    el('img', true).forEach(img => {
   //       if (img.classList.contains('no-img-loader')) return;

   //       let parent = img.parentElement;
   //       let width  = img.getBoundingClientRect().width;
   //       let height = img.getBoundingClientRect().height;

   //       if (!img.classList.contains('d-none')) img.classList.add('d-none');

   //       let span = document.createElement('span');
   //       span.className    = 'img-loader';
   //       span.style.width  = width + 'px';
   //       span.style.height = height + 'px';

   //       if (!parent.querySelector('.img-loader')) parent.insertBefore(span, img); 
         
   //       // img.addEventListener('load', ()=> {
   //       //    img.classList.remove('d-none');
   //       //    span.remove();
   //       // });

   //       function checkImageLoaded(image) {

   //          if (image.naturalWidth > 0) {
   //             img.classList.remove('d-none');
   //             span.style.display = 'none';
               
   //          } else setTimeout(()=> checkImageLoaded(image), 50);
   //       }

   //       checkImageLoaded(img);

   //       // img.addEventListener('error', () => {
   //       //    img.classList.remove('d-none');
   //       //    span.remove();
   //       // });
   //    });
   // }

   
}