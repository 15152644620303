import store from '@/store/store';
const CRUDManager = new (store.getters['classes/getClass']('CRUDManager'))();

/*
   the function to get the suggestions and show it

   options = {
      url         => the api url u will get the suggestions
      input       => input element selector
      suggestions =>  suggestions element selector
      event       => input event
   }
*/

export async function useShowSuggestions (options = {}) {
   if (options.event.keyCode == 40 || options.event.keyCode == 38 || options.event.keyCode == 13 ) return;

   let body   = { search: options.event.target.value ?? ''}
   let {data} = await CRUDManager.get(options.url ?? '', body, ...skip(3), false);

   return data;
}