<template>
   <div class="print_installment_table_8cm" id="print_installment_table_8cm">
      <table v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th v-if="has_discount">{{ $t('lang_discount_price') }}</th>
               <th>{{ $t('lang_early_payment') }}</th>
               <th>{{ $t('lang_amount_monthly_payment') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td v-if="has_discount">{{ $filters.setCurrency(discount, currency) }}</td>
               <td>{{ $filters.setCurrency(installment_head.IHPrepaid, currency) }}</td>
               <td>{{ $filters.setCurrency(installment_head.IHMonthlyPayAmount, currency) }}</td>
            </tr>
         </tbody>
      </table>
      <table v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>#</th>
               <th>{{ $t('lang_payments_date') }}</th>
               <th>{{ $t('lang_should_pay_amount') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(ib , i) in installment_body" :key="ib.IBId">
               <td>{{ i + 1 }}</td>
               <td>{{ ib.IBDateShouldPay }}</td>
               <td>{{ $filters.setCurrency(ib.IBAmountShouldPay, currency) }}</td>
            </tr>
         </tbody>
      </table>
      <table v-table class="table table-white border-dark">
         <thead>
            <tr>
               <th>#</th>
               <th>{{ $t('lang_pay_date') }}</th>
               <th>{{ $t('lang_paid') }}</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(ib , i) in installment_body" :key="ib.IBId">
               <template v-if="ib.IBAmountPaid == 0">
                  <td>{{ i + 1 }}</td>
                  <td></td>
                  <td></td>
               </template>
               <template v-else>
                  <td>{{ i + 1 }}</td>
                  <td>{{ ib.IBPaidDate }}</td>
                  <td>{{ $filters.setCurrency(ib.IBAmountPaid, currency) }}</td>
               </template>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
export default {
   props: {
      currency: String,
      installment_head: {
         type: [Object, Boolean],
         default: false,
      },
      installment_body: {
         type: [Object, Boolean],
         default: false,
      },
      has_discount: {
         type: Boolean,
         default: false,
      },
      discount: {
         type: [String, Number],
         default: 0,
      },
      total_invoice: {
         type: [String, Number],
         default: 0,
      },
      remain: {
         type: [String, Number],
         default: 0,
      },
   },
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

#print_installment_table_8cm {
   table {
      td,th {
         font-size: rem(13);
      }
   }
}
</style>