<template>
   <div class="print_footer_design_2" id="print_footer_design_2">
      <div v-if="shopAddress" class="contact">
         <i class="fa-solid fa-location-dot"></i>
         <span>{{ shopAddress }}</span>
      </div>
      <div v-if="shopSecondAddress" class="contact">
         <i class="fa-solid fa-location-dot"></i>
         <span>{{ shopSecondAddress }}</span>
      </div>
      <p v-if="shopNote" class="note">
         {{ shopNote }}
      </p>
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
   setup () {
      const store = useStore();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopAddress: shop.address || false,
         shopSecondAddress: shop.secondaryAddress || false,
         shopNote: shop.note || false,
      });

      return {
         ...toRefs(state),
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_footer_design_2 {
   display: grid;
   gap: rem(5);
   padding: 1rem;
   margin-top: auto;
   font-size: 1rem;
}
</style>