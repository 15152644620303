const inputEvents = {
   // ~~~~~~~~~~~~~> to force user write only integers
   onlyInt : {
      mounted (el, binding) {
         let check  = binding.value ?? true;
         if (!check) return;
         
         el.addEventListener('input', function() {
            el.value = el.value.replace(/[^0-9]/g, '');
         });
      }
   },

   // ~~~~~~~~~~~~~> to force user write only integers & floats
   floatInt : {
      mounted(el, binding) {
         let check  = binding.value ?? true;
         if (!check) return;

         el.addEventListener('input', function() {
            if (el.value.trim() == '.') el.value = '';
            el.value = el.value.replace(/[^0-9\.]/g, '');
         });
      }
   },

   // ~~~~~~~~~~~~~> to give inputs value of zero and when they focus make it empty and when blur get back what was the value
   valZeroEmptyInt : {
      mounted(el, binding) {
         let check  = binding.value ?? true;
         if (!check) return;

         if (el.value.trim() == '') el.value = 0;
   
         el.addEventListener('focus', function() {
            if (el.value == '') el.setAttribute('input-val', 0);
            else el.setAttribute('input-val', el.value);

            el.value = '';
         });

         el.addEventListener('blur', function() {
            if (el.value.trim() == '') {
               el.value = el.getAttribute('input-val');
               el.removeAttribute('input-val');
            }
         });
      },
   },
};


export default inputEvents;
