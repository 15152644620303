<template>
   <div class="print_installment_table_design_2" id="print_installment_table_design_2">
      <div class="row">
         <div class="col-6">
            <div class="d-flex gap-2">
               <table v-table-print-design="2" class="mt-0">
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>{{ $t('lang_payments_date') }}</th>
                        <th>{{ $t('lang_should_pay_amount') }}</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(ib , i) in installment_body" :key="ib.IBId">
                        <td>{{ i + 1 }}</td>
                        <td>{{ ib.IBDateShouldPay }}</td>
                        <td>{{ $filters.setCurrency(ib.IBAmountShouldPay, currency) }}</td>
                     </tr>
                  </tbody>
               </table>
               <table v-table-print-design="2" class="mt-0">
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>{{ $t('lang_pay_date') }}</th>
                        <th>{{ $t('lang_paid') }}</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(ib , i) in installment_body" :key="ib.IBId">
                        <template v-if="ib.IBAmountPaid == 0">
                           <td>{{ i + 1 }}</td>
                           <td></td>
                           <td></td>
                        </template>
                        <template v-else>
                           <td>{{ i + 1 }}</td>
                           <td>{{ ib.IBPaidDate }}</td>
                           <td>{{ $filters.setCurrency(ib.IBAmountPaid, currency) }}</td>
                        </template>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
         <div class="col-6">
            <div class="row g-0">
               <div class="col-6">
                  <table v-table class="table table-white border-dark mt-0">
                     <thead>
                        <template v-if="has_discount">
                           <tr><th >{{ $t('lang_total_before_discount') }}</th></tr>
                           <tr><th >{{ $t('lang_discount_price') }}</th></tr>
                           <tr><th v-table-print-design-head-bg-clr="2">{{ $t('lang_total_after_discount') }}</th></tr>
                        </template>
   
                        <tr v-if="!has_discount"><th v-table-print-design-head-bg-clr="2">{{ $t('lang_total_amount') }}</th></tr>
                        
                        <tr><th>{{ $t('lang_early_payment') }}</th></tr>
                        <tr><th>{{ $t('lang_amount_monthly_payment') }}</th></tr>
                        <tr><th>{{ $t('lang_remain') }}</th></tr>
                     </thead>
                  </table>
               </div>
               <div class="col-6">
                  <table v-table class="table table-white border-dark mt-0">
                     <thead>
                        <template v-if="has_discount">
                           <tr><td>{{ $filters.setCurrency(total_invoice + discount, currency) }}</td></tr>
                           <tr><td>{{ $filters.setCurrency(discount, currency) }}</td></tr>
                        </template>
   
                        <tr><td v-table-print-design-head-bg-clr="2">{{ $filters.setCurrency(total_invoice, currency) }}</td></tr>
                        
                        <tr><td>{{ $filters.setCurrency(installment_head.IHPrepaid, currency) }}</td></tr>
                        <tr><td>{{ $filters.setCurrency(installment_head.IHMonthlyPayAmount, currency) }}</td></tr>
                        <tr><td>{{ $filters.setCurrency(remain, currency) }}</td></tr>
                     </thead>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      currency: String,
      installment_head: {
         type: [Object, Boolean],
         default: false,
      },
      installment_body: {
         type: [Object, Boolean],
         default: false,
      },
      has_discount: {
         type: Boolean,
         default: false,
      },
      discount: {
         type: [String, Number],
         default: 0,
      },
      total_invoice: {
         type: [String, Number],
         default: 0,
      },
      remain: {
         type: [String, Number],
         default: 0,
      },
   },
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

.print_installment_table_design_2 {
   table {
      font-size: rem(13)
   }
}
</style>