<template>
   <div :id="suggestionId">
      <template v-for="suggest in suggests" :key="suggest">
         <span :data-search="suggest" @click="suggestionsClicked" :class="$isArb() ? '' : 'rtl text-start'">{{ suggest }}</span>
      </template>
   </div>
</template>

<script>
import { reactive, toRefs, watch} from 'vue';
import store from '@/store/store';

export default {
   props: {
      suggestionId: {
         type: String,
         default: 'show_suggestions',
      },

      suggestions: [],
   },
   setup (props, context) {
      const state = reactive({
         count: 0,
         suggests: [],
      });

      const CRUDManager = new (store.getters['classes/getClass']('CRUDManager'))();
   
      function suggestionsClicked(e) {
         state.suggests = [];
         context.emit('suggestion-clicked', e.target.getAttribute('data-search'));
      };

      
      watch(()=> props.suggestions, (newValue) => {
         state.suggests = newValue;
      });

      return {
         ...toRefs(state),

         suggestionsClicked,
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;
@use '@sass/variables' as *;

#show_suppliers_suggestions,
#show_clients_suggestions,
#show_global_search_suggestions,
#show_suggestions {
   position: absolute;
   bottom : 0;
   left: 0;
   transform: translateY(100%);
   width: 100%;
   z-index: 100;
   display: flex;
   flex-direction: column;
   border-radius: rem(5);
   overflow: hidden;
   box-shadow: 0 .5rem .5rem rgba($dark-gray, .2);
   background-color: var(--body-bg);

   > * { 
      padding: .5rem;
      background-color: white;
      cursor: pointer;
      transition: .4s ease;

      &.hovered,
      &:hover { background-color: rgba($soft-gray, .4); }
   }

}
</style>