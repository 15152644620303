import { createRouter, createWebHistory } from 'vue-router';

const home = [
   {
      path: '/',
      name: 'home',
      component: () => import('../views/sidebar/Home.vue'),
   },
];

const shortcuts = [
   {
      path: '/shortcuts',
      name: 'shortcuts',
      component: () => import('../views/sidebar/Shortcuts.vue'),
   },
   {
      path: '/shortcuts/categories',
      name: 'categories',
      component: () => import('../views/pages/Categories.vue'),
   },
   {
      path: '/shortcuts/add_products',
      name: 'addProducts',
      component: () => import('../views/pages/AddProducts.vue'),
   },
   {
      path: '/shortcuts/products/:productName?',
      name: 'products',
      component: () => import('../views/pages/Products.vue'),
   },
   {
      path: '/shortcuts/users',
      name: 'users',
      meta: {
         permissionName: 'users',
      },
      component: () => import('../views/pages/Users.vue'),
   },
   {
      path: '/shortcuts/settings',
      name: 'settings',
      meta: {
         permissionName: 'settings',
      },
      component: () => import('../views/pages/Settings.vue'),
   },
   {
      path: '/shortcuts/drivers',
      name: 'drivers',
      meta: {
         permissionName: 'drivers',
      },
      component: () => import('../views/pages/Drivers.vue'),
   },
   {
      path: '/shortcuts/delegates',
      name: 'delegates',
      meta: {
         permissionName: 'delegates',
      },
      component: () => import('../views/pages/Delegates.vue'),
   },
   {
      path: '/shortcuts/stores',
      name: 'stores',
      meta: {
         permissionName: 'stores',
      },
      component: () => import('../views/pages/Stores.vue'),
   },
   {
      path: '/shortcuts/users_permissions',
      name: 'users_permissions',
      component: () => import('../views/pages/UsersPermissions.vue'),
   },
   {
      path: '/shortcuts/delegates_reports',
      name: 'delegates_reports',
      component: () => import('../views/pages/DelegatesReports.vue'),
   },
];

const expenses = [
   {
      path: '/expenses',
      name: 'expenses',
      component: () => import('../views/sidebar/Expenses.vue'),
   },
   {
      path: '/expenses/expenses_types',
      name: 'expenses_types',
      component: () => import('../views/pages/ExpensesTypes.vue'),
   },
   {
      path: '/expenses/add_expenses',
      name: 'add_expenses',
      component: () => import('../views/pages/AddExpenses.vue'),
   },
   {
      path: '/expenses/expenses_reports',
      name: 'expenses_reports',
      component: () => import('../views/pages/ExpensesReports.vue'),
   },
];

const safes = [
   {
      path: '/safe',
      name: 'safe',
      component: () => import('../views/sidebar/Safe.vue'),
   },
   {
      path: '/safe/stocks',
      name: 'stocks',
      component: () => import('../views/pages/Stocks.vue'),
   },
   {
      path: '/safe/deposit_money_to_safe',
      name: 'deposit_money_to_safe',
      component: () => import('../views/pages/DepositMoneyToSafe.vue'),
   },
   {
      path: '/safe/take_money_from_safe',
      name: 'take_money_from_safe',
      component: () => import('../views/pages/TakeMoneyFromSafe.vue'),
   },
   {
      path: '/safe/transfer_money_to_safe',
      name: 'transfer_money_to_safe',
      component: () => import('../views/pages/TransferMoneyToSafe.vue'),
   },
   {
      path: '/safe/safe_reports',
      name: 'safe_reports',
      component: () => import('../views/pages/SafeReports.vue'),
   },
   {
      path: '/safe/balance',
      name: 'balance',
      component: () => import('../views/pages/SafeMoney.vue'),
   },
   {
      path: '/safe/profits',
      name: 'profits',
      component: () => import('../views/pages/SafeProfits.vue'),
   },
];

const sales = [
   {
      path: '/sales',
      name: 'sales',
      component: () => import('../views/sidebar/Sales.vue'),
   },
   {
      path: '/sales/sales_reports/:invoice?/:currency?',
      name: 'sales_reports',
      component: () => import('../views/pages/SalesReports.vue'),
   },
   {
      path: '/sales/invoice_reports/:invoiceInfo?/:searchType?',
      name: 'invoice_reports',
      component: () => import('../views/pages/InvoiceReports.vue'),
   },
];

const suppliers = [
   {
      path: '/suppliers',
      name: 'suppliers',
      component: () => import('../views/sidebar/Suppliers.vue'),
   },
   {
      path: '/suppliers/add_supplier',
      name: 'add_supplier',
      component: () => import('../views/pages/AddSupplier.vue'),
   },
   {
      path: '/suppliers/suppliers_payments',
      name: 'suppliers_payments',
      component: () => import('../views/pages/SuppliersPayments.vue'),
   },
   {
      path: '/suppliers/suppliers_reports',
      name: 'suppliers_reports',
      component: () => import('../views/pages/SuppliersReports.vue'),
   },
];

const customers = [
   {
      path: '/customers',
      name: 'customers',
      component: () => import('../views/sidebar/Customers.vue'),
   },
   {
      path: '/customers/add_customer',
      name: 'add_customer',
      component: () => import('../views/pages/AddCustomer.vue'),
   },
   {
      path: '/customers/customers_payments',
      name: 'customers_payments',
      component: () => import('../views/pages/CustomersPayments.vue'),
   },
   {
      path: '/customers/customers_debts',
      name: 'customers_debts',
      component: () => import('../views/pages/CustomersDebts.vue'),
   },
   {
      path: '/customers/customers_reports',
      name: 'customers_reports',
      component: () => import('../views/pages/CustomersReports.vue'),
   },
   {
      path: '/customers/customers_all_debts_reports',
      name: 'customers_all_debts_reports',
      component: () => import('../views/pages/CustomersAllDebtsReports.vue'),
   },
   {
      path: '/customers/customers_accounts',
      name: 'customers_accounts',
      component: () => import('../views/pages/CustomersAccounts.vue'),
   },
   {
      path: '/customers/customers_pay_all_debts',
      name: 'customers_pay_all_debts',
      component: () => import('../views/pages/CustomersPayAllDebts.vue'),
   },
   {
      path: '/customers/customers_installment_payments',
      name: 'customers_installment_payments',
      component: () => import('../views/pages/CustomersInstallmentPayments.vue'),
   },
];

const reports = [
   {
      path: '/reports',
      name: 'reports',
      component: () => import('../views/sidebar/Reports.vue'),
   },
   {
      path: '/customers/customer_detailed_reports',
      name: 'customer_detailed_reports',
      component: () => import('../views/pages/CustomerDetailedReports.vue'),
   },
   {
      path: '/store_reports',
      name: 'store_reports',
      component: () => import('../views/pages/StoreReports.vue'),
   },
   {
      path: '/purchases/purchases_reports',
      name: 'purchases_reports',
      component: () => import('../views/pages/PurchasesReports.vue'),
   },
];

const product = [
   {
      path: '/product_page/:productId',
      name: 'product_page',
      component: () => import('../views/pages/ProductPage.vue'),
   },
];

const shop = [
   {
      path: '/shop',
      name: 'shop',
      component: () => import('../views/pages/ShopData.vue'),
   },
];

const backup = [
   {
      path: '/backup',
      name: 'backup',
      component: () => import('../views/pages/Backup.vue'),
   },
];

const notification = [
   {
      path: '/notifications',
      name: 'notifications',
      component: () => import('../views/pages/Notifications.vue'),
   },
];

const invoice = [
   {
      path: '/add_invoice',
      name: 'add_invoice',
      component: () => import('../views/pages/AddInvoice.vue'),
   },
   {
      path: '/edit_invoice/:invoiceId',
      name: 'edit_invoice',
      component: () => import('../views/pages/EditInvoice.vue'),
   },
];

const purchases = [
   {
      path: '/purchases',
      name: 'purchases',
      component: () => import('../views/sidebar/Purchases.vue'),
   },
   {
      path: '/purchases/purchases_reports',
      name: 'purchases_reports',
      component: () => import('../views/pages/PurchasesReports.vue'),
   },
   {
      path: '/purchases/purchases_invoice',
      name: 'purchases_invoice',
      component: () => import('../views/pages/PurchasesInvoice.vue'),
   },
];


const print = [
   {
      path: '/print_delegates_reports',
      name: 'print_delegates_reports',
      component: () => import('../views/print/PrintDelegatesReports.vue'),
      props: route => ({
         id: route.query.id,
         from: route.query.from,
         to: route.query.to,
      }),
   },
   {
      path: '/print_sales_reports',
      name: 'print_sales_reports',
      component: () => import('../views/print/PrintSalesReports.vue'),
      props: route => ({
         from: route.query.from,
         to: route.query.to,
         invoice: route.query.invoice,
         product: route.query.product,
         voucher: route.query.voucher,
         currency: route.query.currency,
      }),
   },
   {
      path: '/print_invoice/:invoiceId?',
      name: 'print_invoice',
      component: () => import('../views/print/PrintInvoice.vue'),
   },
   {
      path: '/print_invoice_8cm/:invoiceId?',
      name: 'print_invoice_8cm',
      component: () => import('../views/print/PrintInvoice8cm.vue'),
   },
   {
      path: '/print_purchase_invoice/:invoiceId?',
      name: 'print_purchase_invoice',
      component: () => import('../views/print/PrintPurchaseInvoice.vue'),
   },
   {
      path: '/print_purchase_invoice_8cm/:invoiceId?',
      name: 'print_purchase_invoice_8cm',
      component: () => import('../views/print/PrintPurchaseInvoice8cm.vue'),
   },
   {
      path: '/print_customers_payments/:invoiceId',
      name: 'print_customers_payments',
      component: () => import('../views/print/PrintCustomersPayments.vue'),
   },
   {
      path: '/print_customers_payments_8cm/:invoiceId',
      name: 'print_customers_payments_8cm',
      component: () => import('../views/print/PrintCustomersPayments8cm.vue'),
   },
   {
      path: '/print_customers_reports/:currency',
      name: 'print_customers_reports',
      component: () => import('../views/print/PrintCustomersReports.vue'),
   },
   {
      path: '/print_customers_all_debts_reports/:type/:currency/:customer?/',
      name: 'print_customers_all_debts_reports',
      component: () => import('../views/print/PrintCustomersAllDebtsReports.vue'),
   },
   {
      path: '/print_customers_pay_all_debts/:customerId/:currency',
      name: 'print_customers_pay_all_debts',
      component: () => import('../views/print/PrintCustomersPayAllDebts.vue'),
   },
   {
      path: '/print_customers_pay_all_debts_8cm/:customerId/:currency',
      name: 'print_customers_pay_all_debts_8cm',
      component: () => import('../views/print/PrintCustomersPayAllDebts8cm.vue'),
   },
   {
      path: '/print_customer_detailed_reports',
      name: 'print_customer_detailed_reports',
      component: () => import('../views/print/PrintCustomerDetailedReports.vue'),
      props: route => ({
         customer: route.query.customer,
         currency: route.query.currency,
         from: route.query.from,
         to: route.query.to,
      }),
   },
   {
      path: '/print_store_reports',
      name: 'print_store_reports',
      component: () => import('../views/print/PrintStoreReports.vue'),
      props: route => ({
         type: route.query.type,
         currency: route.query.currency,
         store: route.query.store,
         category: route.query.category,
      }),
   },
];

print.forEach((obj, i) => {
   if (!obj.meta) {
      obj.meta = {
         showNav: false,
         showGlobalSearch: false,
         fixedBtns: false,
      }

   } else {
      if (!obj.meta.showNav) obj.meta.showNav = false;
      if (!obj.meta.showGlobalSearch) obj.meta.showGlobalSearch = false;
      if (!obj.meta.fixedBtns) obj.meta.fixedBtns = false;
   }

});



const routes = [
   ...home,
   ...shortcuts,
   ...print,
   ...expenses,
   ...safes,
   ...sales,
   ...suppliers,
   ...customers,
   ...reports,
   ...product,
   ...shop,
   ...backup,
   ...invoice,
   ...purchases,
   ...notification,

   {
      path: '/login',
      name: 'login',
      component: () => import('../views/log/Login.vue'),
      meta: { 
         showNav: false,
         auth: false,
         showGlobalSearch: false,
         fixedBtns: false,
      },
   },
   {
      path: '/language',
      name: 'language',
      component: () => import('../views/sidebar/Language.vue'),
   },
   {
      path: '/:catchAll(.*)',
      component: () => import('../views/error/404.vue'),
      meta: { 
         showNav: false,
         showGlobalSearch: false,
         fixedBtns: false,
      }
   },
];

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
});


export default router
