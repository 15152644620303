const filters = {
   setCurrency(number, currency_type = 'dinar') {
      currency_type = currency_type.trim();
      number        += '';
      number        = number.trim();
      
      let lang = getCookie('vue_diamond_pos_lang');

      if (number == 0) return 0;
      else if (currency_type == 'dollar') return parseFloat(number).toLocaleString('en-US') + ' $';
      else if (currency_type == 'dinar') {
         let sign = lang == 'en' ? ' IQD' : 'د.ع' ;
         return parseFloat(number).toLocaleString('en-US') + " " + sign;
      }
   },

   numberFormat(number) {
      return number.toLocaleString('en-US');
   }
}

export default filters;