<template>
   <div class="print_invoice_contact_details_design_2" id="print_invoice_contact_details_design_2">
      <div class="row">
         <!-- supplier  -->
         <div v-if="supplier" class="col-4">
            <h3 class="title">{{ $t('lang_supplier') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_supplier_name') }}</span> :
               <span>{{ supplier.SpName }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ supplier.SpPhone }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_address') }}</span> : 
               <span>{{ supplier.SpAddress }}</span>
            </div>
         </div>
         <!-- customer  -->
         <div v-if="customer" class="col-4">
            <h3 class="title">{{ $t('lang_client') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_customer_name') }}</span> :
               <span>{{ customer.CustName }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ customer.CustPhone }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_address') }}</span> : 
               <span>{{ customer.CustAddress }}</span>
            </div>
         </div>
         <!-- driver  -->
         <div v-if="driver" class="col-4">
            <h3 class="title">{{ $t('lang_driver') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_driver_name') }}</span> :
               <span>{{ driver.DName }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ driver.DPhone }}</span>
            </div>
         </div>
         <!-- delegates  -->
         <div v-if="delegate" class="col-4">
            <h3 class="title">{{ $t('lang_delegate') }}</h3>
            <div class="info-holder">
               <span class="label">{{ $t('lang_delegate_name') }}</span> :
               <span>{{ delegate.DelName }}</span>
            </div>
            <div class="info-holder">
               <span class="label">{{ $t('lang_phone') }}</span> : 
               <span>{{ delegate.DelPhone }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      customer: { type: [Object, Boolean], default: false },
      supplier: { type: [Object, Boolean], default: false },
      driver:   { type: [Object, Boolean], default: false },
      delegate: { type: [Object, Boolean], default: false },
      invoice_type: String,
   },

   setup(props) {
      if (isEmpty(props.driver)) props.driver    = false;
      if (isEmpty(props.delegate)) props.delegate = false;
   }
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

.print_invoice_contact_details_design_2 {
   .title {
      font-size: rem(24);
      margin-bottom: rem(10);
      color: var(--print-main-clr);
      font-weight: bold;
      text-transform: capitalize;
   }

   .info-holder {
      display: flex;
      gap: rem(15);
      margin-bottom: rem(5);
   }
}


</style>