import i18n from "@/language/i18n";
import store from "@/store/store";
import Validation from '@/validation/ValidationTrigger';
import router from "@/router";
import { Logger } from "sass";

const t = i18n.global.t;

export default {
   // ====================== make confirmation function return true or false ======================
   confirmedAction(msg = 'lang_you_want_to_confirm_action', icon = 'info', text = '', confirmButtonColor = '#2778c4', cancelButtonColor = '#757575', confirmBtnTxt = 'lang_yes', cancelBtnTxt = 'lang_no' ) {
      return new Promise((resolve, reject) => {
         Swal.fire({
            title: `<span class="txt-cap">${t(msg)}</span>`,
            text: t(text),
            customClass: { 
               title: 'text-center border-0',
            },
            icon,
            showCancelButton: true,
            confirmButtonColor,
            cancelButtonColor,
            confirmButtonText: `<span class="text-cap">${t(confirmBtnTxt)}</span>`,
            cancelButtonText: `<span class="txt-cap">${t(cancelBtnTxt)}</span>`
            
         }).then((result) => {
            resolve(result.isConfirmed);
         });
      });

   },

   // ====================== make empty data message ======================
   emptyData(parent = '', condition = false, msg = 'lang_no_data') {
      if (document.querySelector(parent + ' #empty_data_msg')) {
         document.querySelector(parent + ' #empty_data_msg').remove();
      }

      if (condition) {
         let msgTag = document.createElement('h3');
         msgTag.setAttribute('id', 'empty_data_msg');
         msgTag.innerText = t(msg);
   
         if (el(parent)) el(parent).appendChild(msgTag);
      }
   },

   // ====================== check language ======================
   isArb() {
      if (getCookie('vue_diamond_pos_lang') == 'en') return false;
      else return true;
   },

   // ====================== Make notifications ======================
   notifications(options = {}) {
      let width = undefined;
      if (window.innerWidth <= 767) {
         options.position = options.toast ? 'top' : 'center';
         width   = '60%';

         if (window.innerWidth <= 400) width  = '100%';
      } else width = undefined;


      let iconColor = () => {

         if (options.type) {
            if (options.toast) return '#fff';

            if (options.type == 'success') return '#87d05d';
            if (options.type == 'warning') return '#da950b';
            if (options.type == 'error')   return '#f27474';
            if (options.type == 'info')    return '#3fc3ee';

         } else return '#87d05d';
      }

      
      Swal.fire({
         toast: options.toast ?? false,
         icon: options.noIcon ?? options.type ?? 'success',
         position: options.position ?? (options.toast ? (this.isArb() ? 'top-start' : 'top-end') : 'center'),
         iconColor: iconColor(),
         customClass: {
            popup: 'colored-toast',
            title: 'text-center border-0',
         },
         title: `<span class="txt-cap mx-2 d-inline-block ${options.fontSize ?? 'fs-20'}">${t(options.msg ?? 'lang_notifications')}</span>`,
         showConfirmButton: options.showConfirmBtn ?? false,
         timer: options.showConfirmBtn ? false : options.time ?? (options.toast ? 2000 : 1250),
         width: options.toast ? width : undefined,
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         },
         timerProgressBar: options.progress ?? true,
      });
   },


   // ====================== Make Success Notification ======================
   success(options = {}) {
      let width = undefined;
      if (window.innerWidth <= 767) {
         options.position = options.toast ? 'top' : 'center';
         width   = '60%';

         if (window.innerWidth <= 400) width  = '100%';
      } else width = undefined;
      
      Swal.fire({
         toast: options.toast ?? false,
         icon: options.noIcon ?? 'success',
         position: options.position ?? (options.toast ? (this.isArb() ? 'top-start' : 'top-end') : 'center'),
         iconColor: options.toast ? 'white' : '#87d05d',
         customClass: {
            popup: 'colored-toast',
            title: 'text-center border-0',
         },
         title: `<span class="txt-cap mx-2 d-inline-block ${options.noIcon ? 'text-success' : ''} ${options.fontSize ?? 'fs-20'}">${t(options.msg ?? 'lang_updated')}</span>`,
         showConfirmButton: options.showConfirmBtn ?? false,
         timer: options.showConfirmBtn ? false : options.time ?? (options.toast ? 2000 : 1250),
         width: options.toast ? width : undefined,
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         },
         timerProgressBar: options.progress ?? true,
      });

   },


   // ====================== Make Waring Notification ======================
   warning(options = {}) {
      let width = undefined;
      if (window.innerWidth <= 767) {
         options.position = options.toast ? 'top' : 'center';
         width   = '60%';

         if (window.innerWidth <= 400) width  = '100%';
      } else width = undefined;
   

      Swal.fire({
         toast: options.toast ?? false,
         icon: options.noIcon ?? 'warning',
         position: options.position ?? (options.toast ? (this.isArb() ? 'top-start' : 'top-end') : 'center'),
         customClass: {
            popup: 'colored-toast',
            title: 'text-center border-0',
         },
         iconColor: options.toast ? 'white' : '#da950b',
         title: `<span class="txt-cap mx-2 d-inline-block ${options.noIcon ? 'text-warning' : ''} ${options.fontSize ?? 'fs-20'}">${t(options.msg ?? 'lang_updated')}</span>`,
         showConfirmButton: options.showConfirmBtn ?? false,
         timer: options.showConfirmBtn ? false : options.time ?? (options.toast ? 2000 : 1250),
         width: options.toast ? width : undefined,
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         },
         timerProgressBar: options.progress ?? true,
      });

   },


   // ====================== function When Editing Data ..  Get The HTML Then Show It In (Swal2) Then Make Validation And Return The Posted Data ======================
   async dataEdit(options = {}) {

      const {value: formPosted} = await Swal.fire({
         title: t(options.title ?? 'lang_edit'),
         html: options.html,
         customClass: { 
            confirmButton: options.confirmBtnClass ?? 'btn-main-clr w-75',
            header: 'align-items-start',
         },
         showClass: {
            popup: 'swal2-show animate__zoomIn',
         },
         hideClass: {
            popup: 'swal2-hide animate__zoomOut',
         },
         showCloseButton: true,
         focusDeny: false,
         focusCancel: false,
         focusConfirm: false,
         confirmButtonText: t(options.confirmBtnTxt ?? 'lang_update'),
         closeButtonHtml: '<i class="fa-solid fa-xmark"></i>',
         allowOutsideClick: () => {
            if (options.clickOutside) return true;
            const popup = Swal.getPopup();
            popup.classList.remove('swal2-show');
            setTimeout(() => {
               popup.classList.remove('animate__zoomIn');
               popup.classList.add('animate__animated', 'animate__headShake');
            });
            setTimeout(() => popup.classList.remove('animate__animated', 'animate__headShake'), 500);
            return false
         },
         willOpen() {
            if (!options.parent) return;

            const submitBtn = el('.swal2-confirm');
            const inputs    = el(options.parent).querySelectorAll('input, textarea');

            inputs.forEach(input => {
               input.onkeyup = (e) => {
                  if (e.keyCode == 13) submitBtn.click();
               }
            });
         },
         didRender() {
            if (!options.parent) return;

            // ########## change some content style
            el('.swal2-content .input-wrapper', true).forEach(element => {
               
               if (element.classList.contains('my-0')) element.classList.remove('my-1', 'my-2', 'my-3', 'my-4', 'my-5');
               if (element.classList.contains('my-1')) element.classList.remove('my-2', 'my-3', 'my-4', 'my-5');
               if (element.classList.contains('my-2')) element.classList.remove('my-1', 'my-3', 'my-4', 'my-5');
               if (element.classList.contains('my-3')) element.classList.remove('my-1', 'my-2', 'my-4', 'my-5');
               if (element.classList.contains('my-4')) element.classList.remove('my-1', 'my-2', 'my-3', 'my-5');
               if (element.classList.contains('my-5')) element.classList.remove('my-1', 'my-2', 'my-3', 'my-4');

            });
            el('.swal2-content').style.textAlign = 'start';

            if (el(options.parent + 'form')) el(options.parent + 'form').className = '';

            // ########## input events
            if (options.events) {
               options.events.forEach(event => {
                  el(options.parent + ' ' + event.element, true).forEach(element => {
                     element.addEventListener(event.name, event.method);
                  });
               })
            }

            // ########## input rules
            if (el(options.parent + ' input[data-only-int]')) {
               el(options.parent + ' input[data-only-int]', true).forEach(inp => {
                  inp.addEventListener('input', function() {
                     this.value = this.value.replace(/[^0-9]/g, '');
                  });
               })
            }

            if (el(options.parent + ' input[data-float-int]')) {
               el(options.parent + ' input[data-float-int]', true).forEach(inp => {
                  inp.addEventListener('input', function() {
                     if (this.value.trim() == '.') this.value = '';
                     this.value = this.value.replace(/[^0-9\.]/g, '');
                  });
               })
            }

            if (el(options.parent + ' input[data-val-zero-empty-int]')) {
               el(options.parent + ' input[data-val-zero-empty-int]', true).forEach(inp => {
                  if (inp.value.trim() == '') inp.value = 0;
            
                  inp.addEventListener('focus', function() {
                     if (inp.value == '') inp.setAttribute('input-val', 0);
                     else inp.setAttribute('input-val', inp.value);
         
                     inp.value = '';
                  });
         
                  inp.addEventListener('blur', function() {
                     if (inp.value.trim() == '') {
                        inp.value = inp.getAttribute('input-val');
                        inp.removeAttribute('input-val');
                     }
                  });
               });
            }



            // ########## some events like show password & phone number & live image
            if (el(options.parent + ' [data-show-pwd-icon]')) {
               el(options.parent + ' [data-show-pwd-icon]').addEventListener('click', function () {
                  let input = this.parentElement.querySelector('input');
                  let attr  = input.getAttribute('type');
   
                  if (attr == 'password') input.setAttribute('type', 'text');
                  else input.setAttribute('type', 'password');
                  
                  this.classList.toggle('fa-eye-slash');
                  this.classList.toggle('fa-eye');
               });
            };

            if (el(options.parent + ' input[data-show-phone-count]')) {
               let input            = el(options.parent + ' input[data-show-phone-count]');
               let span             = input.nextElementSibling;
               let phoneNumberCount = 11;

               if (input.value.trim().length !== 0) span.innerHTML = phoneNumberCount - input.value.length;
         
               input.addEventListener('keyup', function() {
                  span.innerHTML = phoneNumberCount - this.value.length;
            
                  if (span.innerHTML < 0) span.innerHTML = 0;
                  if (span.innerHTML > 11) span.innerHTML = 11;
               });
      
               input.addEventListener('blur', function() {
                  if (input.value.trim().length === 0) span.innerHTML = phoneNumberCount;
               });
            }

            if (el(options.parent + ' input[data-input-live-img]')) {
               el(options.parent + ' input[data-input-live-img]', true).forEach(inp => {
                  inp.addEventListener('change', function(e) {

                     let output = el(options.parent + ' ' + this.getAttribute('data-input-live-img')) || false;
                     if (!output) return;

                     let file = e.target.files[0];

                     options.img = {
                        file,
                        name: inp.getAttribute('id').replace('Edit', '')
                     };

                     output.setAttribute('src', URL.createObjectURL(file));
                     output.addEventListener('load', function () {
                        URL.revokeObjectURL(output.src) // free memory
                     });
                  });
               });
            }
         },
         preConfirm() {
            if (!options.parent) return;

            if (options.validate ?? true) {
               
               let validationOptions = deepCopyArray(options.validationOption);

               validationOptions.forEach(rule => {
                  rule.name = rule.name.replace('Edit', '');
                  rule.name = rule.name + 'Edit';
               });

               let validation = new Validation();
               if (!validation.validating(validationOptions)) return false;
            }

            const inputs = el(options.parent).querySelectorAll('input, textarea, select');
            let values   = {};

            inputs.forEach(el => {

               if (el.type == 'radio') {
                  if (el.checked == true) {
                     values[el.name.replace('Edit', '')]  = el.value
                  }

                  return;
               } 
               
               values[el.name.replace('Edit', '')]  = el.value
            });

            if (options.updateFunction ?? false) {
               if (options.img ?? false) values[options.img.name] = options.img.file;
               
               options.updateFunction(values);
               return false;
            }
   
            return values;
         },
      });

      if (formPosted) return formPosted;
      else return {};
   },


   // ====================== To Print Page In Current Page Or New Tab ======================
   showPrint(url) {
      if (store.getters['settings/getSettings'].newWindowInPrint == '1') openInNewTab(url);
      else router.push(url);
   },


   // ====================== Checking For Permissions If Auth Then Show Page If Not Then Redirect To Home Page======================
   checkPer(permissionName) {
      if ((store.getters['permissions/getPermissions'][permissionName] ?? 'f') == 'f') router.replace('/');
   },


   // ====================== Getting The Html Of Edit Some Page Names & Insert There Values ======================
   getEditHTML(pageName, data) {
      const settings = store.getters['settings/getSettings'];

      const isCarton     = settings.productsHandleType == 'carton' ? true : false;
      const isDollar     = settings.handleProductDinarOrDollarOrBoth == 'dollar' ? true : false;
      const isDinar      = settings.handleProductDinarOrDollarOrBoth == 'dinar' ? true : false;
      const dollarOrBoth = settings.handleProductDinarOrDollarOrBoth == 'dollar' || settings.handleProductDinarOrDollarOrBoth == 'both' ? true : false;
      const dinarOrBoth  = settings.handleProductDinarOrDollarOrBoth == 'dinar' || settings.handleProductDinarOrDollarOrBoth == 'both' ? true : false;
      const bothCurrency = settings.handleProductDinarOrDollarOrBoth == 'both' ? true : false;

      let html                   = ``;
      let inputClass             = 'form-control';
      let inputSelectClass       = 'form-select';
      let inputCheckClass        = 'form-check-input';
      let inputWrapperClass      = 'input-wrapper';
      let inputCheckWrapperClass = 'form-check';
      let inputCheckLabelClass   = 'form-check-label';
      let labelClass             = 'form-label';

      if (pageName == 'expenses') {
         let expense       = data.expense;
         let expensesTypes = data.expenses_types;
         
         let expensesTypesSelectOptions = ``;
         expensesTypes.forEach(ex => expensesTypesSelectOptions += `<option value="${ex.ETId}" ${expense.EETId == ex.ETId ? 'selected' : ''}>${ex.ETName}</option>`);
         
         html +=`
            <div id="edit_expense_html" class="edit_expense_html">
               <input type="hidden" name="currencyEdit" value="${expense.ECurrencyType}"/>
               <input type="hidden" name="oldAmountEdit" value="${expense.EAmount}"/>
               <div class="${inputWrapperClass}">
                  <label for="amountEdit" class="${labelClass}">${t('lang_amount')}</label>
                  <input type="text" name="amountEdit" id="amountEdit" class="${inputClass}" value="${expense.EAmount}" data-float-int/>
               </div>
               <div class="${inputWrapperClass}">
                  <label for="noteEdit" class="${labelClass}">${t('lang_notes')}</label>
                  <textarea type="text" name="noteEdit" id="noteEdit" class="${inputClass}">${expense.ENote}</textarea>
               </div>
               <div class="${inputWrapperClass}">
                  <label for="expenseTypeEdit" class="${labelClass}">${t('lang_expense_type')}</label>
                  <select name="expenseTypeEdit" id="expenseTypeEdit" value="${expense.EETId}" class="${inputSelectClass}">
                     ${expensesTypesSelectOptions}
                  </select>
               </div>
            </div>
         `;
      }


      if (pageName == 'suppliers_payment') {
         let supplierPayment = data;

         html +=`
         <div id="suppliers_payment_html" class="suppliers_payment_html">
            <input type="hidden" name="currencyEdit" value="${expense.ECurrencyType}"/>
            <input type="hidden" name="oldAmountEdit" value="${expense.EAmount}"/>
            <div class="${inputWrapperClass}">
               <label for="amountEdit" class="${labelClass}">${t('lang_amount')}</label>
               <input type="text" name="amountEdit" id="amountEdit" class="${inputClass}" value="${expense.EAmount}" data-float-int/>
            </div>
            <div class="${inputWrapperClass}">
               <label for="noteEdit" class="${labelClass}">${t('lang_notes')}</label>
               <textarea type="text" name="noteEdit" id="noteEdit" class="${inputClass}">${expense.ENote}</textarea>
            </div>
            <div class="${inputWrapperClass}">
               <label for="expenseTypeEdit" class="${labelClass}">${t('lang_expense_type')}</label>
               <select name="expenseTypeEdit" id="expenseTypeEdit" value="${expense.EETId}" class="${inputSelectClass}">
                  ${expensesTypesSelectOptions}
               </select>
            </div>
         </div>
      `;
      }


      return html;
   },

   
   // ====================== checking if there is notifications ======================
   checkNotification() {
      if (store.getters['notifications/getHasNotification']) {

         this.warning({
            msg: 'lang_you_have_new_notifications',
            time: 3000,
            position: 'top',
            toast: true,
         });

         setTimeout(() => {
            if (el('#fixed_notification_btn')) el('#fixed_notification_btn').classList.add('animate');
         });
         
      } else {
         setTimeout(() => {
            if (el('#fixed_notification_btn')) el('#fixed_notification_btn').classList.remove('animate');
         });
      }

      if (store.getters['notifications/getHasSoundEffect'] == 'on') {
         let audio = new Audio(require('@/assets/sound/' + NOTIFICATION_SOUND_NAME));
         audio.play();
      }
   },

}
