let el_place = -1;

/*
   the function make you move to search suggestions with up and down key on keyboard

   options = {
      input       => input element selector
      suggestions =>  suggestions element selector
      addedClass  => the class name that will add when hover on a suggestions [default => hovered]
      event       => input event
   }

   keyboard key code:  
   [down]  => 40,
   [up]    => 38,
   [enter] => 13,
*/

export function useMoveSuggestions (options = {}) {
   // if suggestions are empty return the function
   if (el(options.suggestions).innerHTML.trim() == '' ) return;

   let down  = options.event.keyCode == 40 ? true : false;
   let up    = options.event.keyCode == 38 ? true : false;
   let enter = options.event.keyCode == 13 ? true : false;
   
   // get the elements 
   let suggestedElements    = el(options.suggestions + ' > *', true);
   let input                = el(options.input);
   let search               = el(options.input).value;
   let length               = suggestedElements.length;
   let addedClass           = isEmpty(options.addedClass) ? 'hovered' : options.addedClass;


   if (down) {
      el_place++

      if (el_place == length) el_place = length - 1;
      if (el_place < 0) el_place = 0;

      suggestedElements.forEach(el => el.classList.remove(addedClass));
      suggestedElements[el_place].classList.add(addedClass);

   } else if (up) {
      el_place--

      if (el_place >= length) el_place = length - 2;
      if (el_place < 0) {

         suggestedElements.forEach(el => el.classList.remove(addedClass));
         return;
      }
      
      suggestedElements.forEach(el => el.classList.remove(addedClass));
      suggestedElements[el_place].classList.add(addedClass);
      
   }

   if (enter) {
      suggestedElements.forEach( el => {
         if (el.classList.contains(addedClass)) search = el.getAttribute('data-search');
      });

      input.value = search;
      el_place    = -1;

      return search;
   }
}