<template>
   <div class="print_invoice_money_details_design_1" id="print_invoice_money_details_design_1">
      <div class="row justify-content-between mb-3">
         <!-- singles & cartons  -->
         <div class="col-5">
            <div class="row g-0">
               <div class="col-6">
                  <table v-table class="table table-white border-dark">
                     <thead>
                        <tr><th>{{ $t('lang_carton_number') }}</th></tr>
                        <tr><th>{{ $t('lang_one_piece_number') }}</th></tr>
                     </thead>
                  </table>
               </div>
               <div class="col-6">
                  <table v-table class="table table-white border-dark">
                     <thead>
                        <tr><td>{{ cartons }}</td></tr>
                        <tr><td>{{ singles }}</td></tr>
                     </thead>
                  </table>
               </div>
            </div>
         </div>
         <!-- debts & totals  -->
         <div class="col-6">
            <div class="row g-0">
               <div class="col-6">
                  <table v-table class="table table-white border-dark">
                     <thead>
                        <template v-if="has_discount">
                           <tr><th >{{ $t('lang_total_before_discount') }}</th></tr>
                           <tr><th >{{ $t('lang_discount_price') }}</th></tr>
                           <tr><th v-table-print-design-head-bg-clr="1">{{ $t('lang_total_after_discount') }}</th></tr>
                        </template>

                        <tr v-if="!has_discount"><th v-table-print-design-head-bg-clr="1">{{ $t('lang_total_amount') }}</th></tr>
                        
                        <template v-if="invoice_type != 'fast_invoice'">
                           <tr><th>{{ $t('lang_old_debt') }}</th></tr>
                           <tr><th>{{ $t('lang_payed') }}</th></tr>
                           <tr><th>{{ $t('lang_remain') }}</th></tr>
                           <tr><th>{{ $t('lang_total_debts') }}</th></tr>
                        </template>
                     </thead>
                  </table>
               </div>
               <div class="col-6">
                  <table v-table class="table table-white border-dark">
                     <thead>
                        <template v-if="has_discount">
                           <tr><td>{{ $filters.setCurrency(total_invoice + discount, currency) }}</td></tr>
                           <tr><td>{{ $filters.setCurrency(discount, currency) }}</td></tr>
                        </template>

                        <tr><td v-table-print-design-head-bg-clr="1">{{ $filters.setCurrency(total_invoice, currency) }}</td></tr>
                        
                        <template v-if="invoice_type != 'fast_invoice'">
                           <tr><td>{{ $filters.setCurrency(old_debts, currency) }}</td></tr>
                           <tr><td>{{ $filters.setCurrency(payed, currency) }}</td></tr>
                           <tr><td>{{ $filters.setCurrency(remain, currency) }}</td></tr>
                           <tr><td>{{ $filters.setCurrency(total_debts, currency) }}</td></tr>
                        </template>
                     </thead>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      currency: String,
      has_discount: {
         type: Boolean,
         default: false,
      },
      discount: {
         type: [String, Number],
         default: 0,
      },
      total_invoice: {
         type: [String, Number],
         default: 0,
      },
      invoice_type: {
         type: String,
         default: '',
      },
      singles: {
         type: [String, Number],
         default: 0,
      },
      cartons: {
         type: [String, Number],
         default: 0,
      },
      old_debts: {
         type: [String, Number],
         default: 0,
      },
      total_debts: {
         type: [String, Number],
         default: 0,
      },
      payed: {
         type: [String, Number],
         default: 0,
      },
      remain: {
         type: [String, Number],
         default: 0,
      },

   },
}
</script>