<template>
   <div id="global_search" class="global_search">
      <div class="container">
         <div id="global_search_btns">
            <router-link to="/add_invoice" id="new_tab_btn" target="_blank" class="border border-1" tooltip :tt-text="$t('lang_open_new_tab')" :tt-dir="$isArb() ? 'right' : 'left'" tt-fw-bold>
               <i class="fa-regular fa-window-restore"></i>
               <i class="fa-solid fa-plus"></i>
            </router-link>
            <button type="button" @click="previousPage"  class="border border-1" id="prv_page_btn" tooltip :tt-dir="$isArb() ? 'right' : 'left'" tt-fw-bold :tt-text="$t('lang_previous_page')"><i class="fa-solid fa-left-long"></i></button>
         </div>
         <form @submit.prevent="search" @keydown.enter="searching" id="global_search_form">
            <base-input
               name="global_search" 
               id="global_search_input"
               v-model="globalSearch"
               :input="suggestions"
               :keyup="move"
               placeholder=""
               
               parent-class="input-group input-holder mt-0 mb-0"

               :labelBtnClass="`btn-main-clr ${isMobile ? (globalSearch.trim() != '' ? '' : 'disable-btn') : ''}`"
               :label-btn="true"
               label-btn-id="global_search_btn"
               
               :has-extra-el="true"
               extra-el-class="show-placeholder"
               :extra-el-txt="`🔹 ${$t('lang_item_name')} 🔹 ${$t('lang_barcode')} 🔹 ${$t('lang_product_code')} 🔹 ${$t('lang_invoice_number')}🔹 ${$t('lang_voucher_number')}`"
            />

            <!-- search suggestion  -->
            <suggestions-comp suggestionId="show_global_search_suggestions"  :suggestions="suggests" @suggestion-clicked="(value) => {globalSearch = value; search()} " />
         </form>
      </div>
   </div>
</template>

<script>
import functions            from '@/utils/functions';
import {useMoveSuggestions} from '@/utils/composable/useMoveSuggestions';
import {useShowSuggestions} from '@/utils/composable/useShowSuggestions';
import SuggestionsComp      from '@/components/global/SuggestionsComp.vue';

import router               from '@/router';
import store                from '@/store/store';
import { toRefs, reactive, onMounted }   from 'vue';

export default {
   components: {
      SuggestionsComp,
   },
   setup () {
      const state = reactive({
         globalSearch: '',
         isMobile: false,
         suggests: [],
      });
      
      // ===== CRUDManager Class
      const CRUDManager = new (store.getters['classes/getClass']('CRUDManager'))();

      async function search() {
         let value = state.globalSearch.trim();
         
         if (isEmpty(value)) {
            functions.warning({msg: 'lang_plz_write_search_info', toast: true});
            return;
         }
         
         let body = { search: state.globalSearch };
         let {data} = await CRUDManager.get('global_search', body);

         if (isEmpty(data.report)) {
            functions.warning({msg: 'lang_no_data_for_search_result', toast: true, time: 2000});
            return;
         }

         if (data.isMultiResults) {
            functions.confirmedAction('lang_data_same_results_looking_for_invoice').then(resp => {
               if (resp) {
                  if (data.type == 'invoice') router.replace(`/sales/invoice_reports/${data.report.invoice.IId}/invoice_number`);
                  else if (data.type == 'invoice_voucher') router.replace(`/sales/invoice_reports/${data.report.invoiceVoucher.IWslNum}/invoice_voucher`);
                  
               } else router.replace(`/product_page/${data.report.product.PId}`);
            })
         } else {
            if (data.type == 'invoice') router.replace(`/sales/invoice_reports/${data.report.IId}/invoice_number`);
            else if (data.type == 'invoice_voucher') router.replace(`/sales/invoice_reports/${data.report.IWslNum}/invoice_voucher`);
            else if (data.type == 'product') router.replace(`/product_page/${data.report.PId}`);
         }

         state.globalSearch = '';
         el('#global_search_input').blur();
      }

      function searching(e) {
         e.preventDefault();

         if (e.keyCode == 13 && isEmpty(el('#global_search #show_global_search_suggestions').innerHTML.trim())) search();
      }

      function move(event) {
         let value = useMoveSuggestions({
            input:       '#global_search_input',
            suggestions: '#show_global_search_suggestions',
            event,
         });
         
         if (isEmpty(value)) return;
         state.globalSearch = value;
         state.suggests     = [];

         search();
      }
      
      async function suggestions(event) {
         let data = await useShowSuggestions({
            url: 'global_controller/show_items_suggestions',
            event,
         });

         state.suggests = data;
      }

      onMounted(()=> {
         document.addEventListener('click', function(e) {
            if (findParent(e.target, '#show_global_search_suggestions')) return;
            state.suggests = [];
         });

         setMainHeadingPadding();
      });

      function checkMobileSize() {
         if (window.innerWidth >= 768) state.isMobile = false;
         else state.isMobile = true;
      }
      checkMobileSize();

      function setMainHeadingPadding() {
         const global_search_form  = el('#global_search_form');
         const global_search_btns  = el('#global_search_btns');

         if (global_search_form) {
            const global_search_width      =  global_search_form.getBoundingClientRect().width;
            const global_search_btns_width = global_search_btns.getBoundingClientRect().width;
            const result = global_search_width + global_search_btns_width;

            document.body.style.setProperty('--global_search_width', result + 'px');
         }

      }

      window.addEventListener('resize', ()=> {
         setMainHeadingPadding();
         checkMobileSize();
      });
   
      function previousPage() {
         router.go(-1);
      }

      return {
         ...toRefs(state),

         search,
         searching,
         suggestions,
         move,
         previousPage,
      }
   }
}
</script>

<style lang="scss">
@use '@sass/variables' as *;
@use '@sass/helpers' as *;


   // global search & previous page btn
   .global_search {
      --btn_position: 3;
      --btn_padding_x: 1;
      --top: 2.7rem;
      --global_search_position: calc(((var(--btn_position) * 2) + ( var(--btn_padding_x) * 2 ) + .5) * 1rem);

      .container { 
         position: relative;
      }

      #global_search_btns {
         position: absolute;
         left: 0rem;
         top: var(--top);
         display: flex;
         align-items: stretch;
         justify-content: center;
         gap: rem(5);
         flex-wrap: wrap;
         width: fit-content;
      }

      // prv & new tab buttons
      #prv_page_btn {
         display: block;
         width: fit-content;
         margin-right: auto;
         padding: .4rem calc(var(--btn_padding_x) * 1rem);
         background-color: #2a3e52;
         color: #fff;
      }

      #new_tab_btn {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: rem(5);
         width: fit-content;
         margin-right: auto;
         padding: .4rem calc(var(--btn_padding_x) * 1rem);
         background-color: #2a3e52;
         color: #fff;
      }
      
      // global Search Form
      #global_search_form {
         position: absolute;
         display: block;
         width: 50%;
         margin-right: auto;
         left: var(--global_search_position); 
         top: var(--top);

         @include brTo(sm) {
            position: relative;
            margin-bottom: 1rem;
            width: calc(100% - var(--global_search_position));
            input::placeholder {
               font-size: rem(13);
               font-weight: bold;
            }
         }
         
         .input-holder {
            position: relative;
            overflow: hidden;


            #global_search_input:not(:placeholder-shown) + span,
            #global_search_input:focus + span { display: none }

            .show-placeholder {
               position: absolute;
               left: 0;
               top: 50%;
               z-index: 10;
               display: flex;
               width: max-content;
               pointer-events: none;
               transform: translate(100%, -50%);
               animation: show-news 15s linear infinite reverse;
               
               @include brTo(sm) { animation: show-news 22s linear infinite reverse }
            }

            #global_search_input::placeholder { opacity: 0} 

            #global_search_btn {
               position: relative;
               z-index: 11;
            }
         }
      }
   }
</style>