<template>
   <div class="print_header_design_1" :class="$isArb() ? '': 'flex-row-reverse'" id="print_header_design_1" :style="`background-image: url('${require('@shopImgs/print_top.jpg')}') !important`">
      
      <div class="title">
         <h2>
            <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopImg}`" class="rounded-img-75 no-img-loader" alt="Shop Logo">
            {{ shopName }}
         </h2>
         <p class="desc">{{ titleMsg  }}</p>
      </div>

      <div class="info">
         <!-- currency  -->
         <div v-if="invoiceNumber" class="currency">
            <h3 class="title">{{ $t('lang_invoice')}}</h3>
            <span>{{ $t(currencyName) }}</span>
         </div>
   
         <!-- invoice  -->
         <div v-if="invoiceNumber" class="invoice_number">
            <span class="label">{{ $t('lang_invoice_number') }}</span>
            <span>{{ invoiceNumber }}</span>
         </div>
   
         <!-- invoice date  -->
         <div class="invoice_date">
            <span class="label" >{{$t('lang_date')}}</span> :
            <span>{{ date }}</span>
         </div>
   
         <!-- invoice time  -->
         <div class="invoice_time">
            <span class="label" >{{$t('lang_time')}}</span> :
            <span>{{ time }}</span>
         </div>
      </div>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      currency: String,
      invoiceNumber: {
         type: [String, Boolean],
         default: false,
      },
      title: {
         type: [String, Boolean],
         default: false,
      },
      date: {
         type: String,
         default: getDate(),
      },
      time: {
         type: String,
         default: getTime(),
      }
   },

   setup (props) {
      const store = useStore();
      const { t } = useI18n();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopName: shop.name,
         shopImg: shop.image,

         URL_PATH,
         UPLOADS_IMG_FOLDER_NAME,
      });

      const currencyName = computed(()=> {
         if (props.currency == 'dollar') return 'lang_in_dollar';
         else return 'lang_in_dinar';
      });

      const titleMsg = computed(()=> {
         if (props.title == false) return store.getters['shop/getShop'].description;
         else return t(props.title);
      });
   
      return {
         ...toRefs(state),

         currencyName,
         titleMsg,
      }
   }
}
</script>


<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_header_design_1 {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: rem(20);
   min-height: rem(150);
   height: fit-content;

   background-size: cover !important;
   background-color: transparent !important;
   background-repeat: no-repeat !important;
   background-position: center !important;

   @media print { background-position: 40% !important; }


   .title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: rem(20);
      width: 50%;
      color: #fff !important;
      overflow-x: hidden;
      text-align: center;
      text-transform: capitalize;

      
      h1,h2,h3,h4 {
         display: flex;
         align-items: center;
         text-transform: capitalize;
         gap: 1rem;
      }

   }

   .label {
      font-weight: bolder; 
      text-transform: capitalize; 
   }

   .currency,
   .invoice_time,
   .invoice_date,
   .invoice_number {
      display: flex;
      gap: rem(15);
      margin-bottom: rem(15);
      text-transform: capitalize;
   }

   .currency {
      font-size: rem(25);
      font-weight: bolder;
      text-transform: capitalize;

      .title {
         color: var(--print-main-clr) !important;
      }
   }
}
</style>