<template>
   <div class="container">
      <div class="sidebar" id="sidebar">
         <button class="expand">
            <i class="fas fa-arrow-left"></i>
            <div class="ham-btn">
               <span></span>
            </div>
         </button>
         <div class="nav-holder">
            <nav class="nav">
               <ul>
                  <li v-for="page in pages" :key="page.path" class="nav__item">
                     <router-link v-if="!page.isBtn" :to="page.path" class="nav__link" :class="path == page.path ? 'active' : ''" tooltip-overflow :tt-text="$t(page.name)" :tt-dir="$isArb() ? 'left' : 'right'">
                        <img :src="require(`@mainImgs/${page.imgName}`)" :alt="page.imgAlt" class="no-img-loader">
                        <p class="title">
                           <span>{{$t(page.name)}}</span>
                        </p>
                     </router-link>
                     <button v-else-if="page.isBtn && page.path == 'logout'" @click="logout" class="nav__link" tooltip-overflow :tt-text="page.name" :tt-dir="$isArb() ? 'left' : 'right'">
                        <img :src="require(`@mainImgs/${page.imgName}`)" :alt="page.imgAlt" class="no-img-loader">
                        <p class="title">
                           <span>{{$t(page.name)}}</span>
                        </p>
                     </button>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
   </div>
</template>

<script>
import i18n        from '@/language/i18n';
import functions   from '@/utils/functions';
import store       from '@/store/store';
import router      from '@/router';
import { useRoute } from 'vue-router';

import {ref, onMounted, watch} from 'vue';

export default {
   name: 'sidebar',
   setup() {
      const route = useRoute();

      let path    = ref('');
      path.value  = '/' + route.fullPath.split('/')[1];

      router.beforeEach((to, from, next)=> {
         path.value = '/' + to.fullPath.split('/')[1];
         next();
      });

      // sidebar pages
      let pages = ref([
         {
            path: '/',
            name: 'lang_home',
            imgName: 'home.png',
            imgAlt: 'Home Icon',
            isBtn: false,
         },
         {
            path: '/shortcuts',
            name: 'lang_shortcuts',
            imgName: 'settings.png',
            imgAlt: 'Settings Icon',
            isBtn: false,
         },
         {
            path: '/sales',
            name: 'lang_sales',
            imgName: 'shopping-cart-sale.png',
            imgAlt: 'Shopping Cart Icon',
            isBtn: false,
         },
         {
            path: '/safe',
            name: 'lang_stock',
            imgName: 'money-bag.png',
            imgAlt: 'Money Bag Icon',
            isBtn: false,
         },
         {
            path: '/expenses',
            name: 'lang_expenses',
            imgName: 'pie-chart.png',
            imgAlt: 'Statistics Icon',
            isBtn: false,
         },
         {
            path: '/customers',
            name: 'lang_customers',
            imgName: 'group.png',
            imgAlt: 'Users Group Icon',
            isBtn: false,
         },
         {
            path: '/reports',
            name: 'lang_reports',
            imgName: 'reports.png',
            imgAlt: 'Reports Icon',
            isBtn: false,
         },
         {
            path: '/suppliers',
            name: 'lang_suppliers',
            imgName: 'delivery-boy.png',
            imgAlt: 'Delivery Boy Icon',
            isBtn: false,
         },
         {
            path: '/purchases',
            name: 'lang_buyers',
            imgName: 'shopping-cart.png',
            imgAlt: 'Shopping Cart Icon',
            isBtn: false,
         },
         {
            path: '/shop',
            name: 'lang_shop_data',
            imgName: 'shop.png',
            imgAlt: 'Shop Icon',
            isBtn: false,
         },
         {
            path: '/language',
            name: 'lang_change_language',
            imgName: 'translation.png',
            imgAlt: 'Translate Icon',
            isBtn: false,
         },
         {
            path: 'logout',
            name: 'lang_logout',
            imgName: 'logout.png',
            imgAlt: 'Logout Icon',
            isBtn: true,
         },
      ]);

      // when click to logout btn
      function logout() {
         functions.confirmedAction().then(async resp => {
            if (resp) {

               setCookie(JWT_COOKIE_NAME, '', -1, 'second');
               
               let {data} = await shortFetch(API_URL + 'logout');

               if (!data.ok) {
                  await store.dispatch('user/changAuth', false);
                  router.push('/login');
               }
            }
         });
      }
      
      // sidebar actions 
      onMounted (() => {
         if (!el('.sidebar')) return;
         
         const body              = document.body;
         const sidebar           = el('.sidebar');
         const sidebarExpandBtn  = el('.sidebar .expand');
         const navLinks          = el('#sidebar .nav__link', true);

         let staticSidebarWidthWhenExpand = 0;


         // close sidebar after clicking on link in mobile 
         navLinks.forEach(link => {
            link.addEventListener('click', () => {
               if (window.innerWidth > 768) return;
               sidebarExpandBtn.click();
            })
         });

         //=== insert padding for body width sidebar width
         function onlyBodyPadding(dir) {
            let sidebarWidth = sidebar.getBoundingClientRect().width;
            if (window.innerWidth < 768) {
               body.style.paddingLeft = 0;
               body.style.paddingLeft = 0;
            } else if (dir == 'right') {
               body.style.paddingRight = sidebarWidth + 'px';
               body.style.paddingLeft  = 0;
            } else {
               body.style.paddingLeft  = sidebarWidth + 'px';
               body.style.paddingRight = 0;
            }
         }

         function bodyPadding(dir) {
            let sidebarWidth = sidebar.getBoundingClientRect().width;
            if (window.innerWidth < 768) {
               body.style.paddingLeft = 0;
               body.style.paddingLeft = 0;
            } 
            else if (dir == 'right') {
               body.style.paddingRight = sidebarWidth + 'px';
               body.style.paddingLeft  = 0;
               window.addEventListener('resize', ()=> body.style.paddingRight = sidebarWidth + 'px');
            } else {
               body.style.paddingLeft  = sidebarWidth + 'px';
               body.style.paddingRight = 0;
               window.addEventListener('resize', ()=> body.style.paddingLeft = sidebarWidth + 'px');
            }
         }

         if (store.getters['config/getLang'] == 'en') bodyPadding('left'); 
         else bodyPadding('right');

         function expandSidebar(isWindowResize = false) {
            let sidebarWidthWhenExpand = sidebar.getBoundingClientRect().width * 3.5;
            
            sidebar.classList.remove('off-transition');
            
            if (sidebar.classList.contains('expanded')) {
               if (!sidebarExpandBtn.querySelector('i').classList.contains('flip')) {
                  sidebarExpandBtn.querySelector('i').classList.add('flip');
               }

               navLinks.forEach(link => link.removeAttribute('tooltip-overflow'));
               
               if (window.innerWidth >= 768) {
                  if (!isWindowResize) {
                     sidebar.style.width = sidebarWidthWhenExpand + 'px';
                     
                     if (store.getters['config/getLang'] == 'en') body.style.paddingLeft =  sidebarWidthWhenExpand + 'px';
                     else body.style.paddingRight =  sidebarWidthWhenExpand + 'px';
                  } else {
                     if (sidebar.style.width == '100%') sidebar.style.width = staticSidebarWidthWhenExpand + 'px';
                     
                     if (store.getters['config/getLang'] == 'en') body.style.paddingLeft =  staticSidebarWidthWhenExpand + 'px';
                     else body.style.paddingRight =  staticSidebarWidthWhenExpand + 'px';
                  }
                  
               } else if (window.innerWidth < 450) {
                  sidebar.style.width =  '100%';
                  
                  if (store.getters['config/getLang'] == 'en') body.style.paddingLeft = 0;
                  else body.style.paddingRight = 0;
               
               } else {
                  if (!isWindowResize) sidebar.style.width =  sidebarWidthWhenExpand + 'px';
                  else sidebar.style.width = staticSidebarWidthWhenExpand + 'px';
                  
                  if (store.getters['config/getLang'] == 'en') body.style.paddingLeft = 0;
                  else body.style.paddingRight = 0;
               }
               
            } else {
               sidebarExpandBtn.querySelector('i').classList.remove('flip');
               navLinks.forEach(link => link.setAttribute('tooltip-overflow', ''));
               if (window.innerWidth >= 768) {
                  if (store.getters['config/getLang'] == 'en') body.style.paddingLeft =  'var(--sidebar-width)';
                  else body.style.paddingRight =  'var(--sidebar-width)';
                  
               } else {
                  body.style.paddingRight = 0;
                  body.style.paddingLeft = 0;
               }
               
               sidebar.style.width ='var(--sidebar-width)';
            }
         }
         
         expandSidebar();
         //=== when clicking on expand sidebar btn 
         sidebarExpandBtn.addEventListener('click', function () {
            body.style.transition = '.5s ease';
            sidebar.classList.toggle('expanded');

            if (sidebar.classList.contains('expanded') && sidebar.style.width != '100%') {
               staticSidebarWidthWhenExpand = sidebar.getBoundingClientRect().width * 3.5;
            }
            expandSidebar();
         })
         
         window.addEventListener('resize', ()=> {
            expandSidebar(true);
         });

         //=== watch changing language we change body padding
         watch(()=> store.state.config.language, (newVal)=> {
            sidebar.classList.add('off-transition');
            if (newVal == 'en') onlyBodyPadding('left'); 
            else onlyBodyPadding('right');
         });

         //=== on clicking to page not sidebar if sidebar open make it close only through 767px (mobile & tablet) of window width
         document.addEventListener('click', function(e) {
            let el         = e.target;
            let element    = null;
            let isSidebar  = false;

            if (window.innerWidth >= 768) return;

            // Traverse up the DOM tree until there are no more parent nodes
            while (el.parentNode) {
               element = el;
               el      = el.parentNode;

               if (element.getAttribute('id') == 'sidebar' || e.target.getAttribute('id') == 'sidebar') {
                  isSidebar = true;
               }
            }

            if (!isSidebar) {
               if (sidebar.classList.contains('expanded')) sidebarExpandBtn.click();
            }
         });
      });

      return {
         pages,
         path,

         logout,
      }
   },
}

</script>


<style lang="scss" scoped>
   @use '@sass/helpers' as *;
   @use '@sass/variables' as *;

   .sidebar {
      --sidebar-transition: .5s ease;
      $nav-link-x-padding : rem(15);

      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 999;
      width: var(--sidebar-width);
      direction: ltr;
      background-color: var(--sidebar-bg);
      &:not(.off-transition) {transition: var(--sidebar-transition) }

      @include brTo(sm) { 
         transform: translateX(100%);
      }

      .expand {
         display: block;
         margin-right: auto;
         width: fit-content;
         padding: rem(5) rem(10);
         background-color: var(--body-bg);
         transition: var(--sidebar-transition);

         
         @include brTo(sm) { 
            background-color: #fff;
            transform: translateX(-100%);
            box-shadow: rem(3) rem(3) rem(13.5) rgba($dark-clr, .3);
         }
         
         i {
            transition: .5s ease;
            &.flip { transform: rotate(180deg)}
            @include brTo(sm) { display: none }
         }

      }

      .ham-btn {
         position: relative;
         height: rem(28);
         width: rem(28);
         cursor: pointer;

         span,
         span::before,
         span::after {
            background: #333; 
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: rem(3);         
            margin-top: rem(12);
            rotate: 180deg;
            transition: .5s ease-in-out;
            border-radius: 100vw;
         }

         span::before { margin-top: rem(-8) }
         span::after { margin-top: rem(8) }

         @include br(sm) { display: none }
      }

      .nav-holder {
         height: 100%;
         overflow-y: auto;
         padding: rem(10) 0 rem(25);
      }

      .nav {
         ul { 
            width: 100%;
            padding: 0 0 rem(15);
            transition: var(--sidebar-transition);
         }

         &__link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: rem(12) $nav-link-x-padding;
            text-transform: capitalize;
            overflow: hidden;
            transition: var(--sidebar-transition);
            
            &::before {
               content:'';
               position: absolute;
               z-index: -1;
               top: rem(2.5);
               right: rem(5);
               left: rem(5);
               bottom: rem(2.5);
               border-radius: rem(5);
               background-color: darken($body-bg, 2%);
               transition: var(--sidebar-transition);
               opacity: 0;
               pointer-events: none;
            }

            &.active,
            // &.router-link-exact-active,
            // &.router-link-active,
            &:hover { 
               &::before {
                  opacity: 1;
               }
            }

            img { 
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
               width: rem(25);
               transition: var(--sidebar-transition);
            }
            
            .title {
               display: grid;
               grid-template-columns: 0fr;
               margin-left: auto;
               transition: var(--sidebar-transition);
               overflow: hidden;
            }
            span { 
               transition: var(--sidebar-transition);
               white-space: nowrap;
               overflow: hidden;
            }
         }
      }
      
      &.expanded {
         transform: translateX(0%);
         .expand { 
            transform: translateX(0%);
            box-shadow: none;
         }

         .ham-btn {
            span { background: transparent }
      
            span::before {
               margin-top: 0;
               rotate: 45deg;
            }
            
            span::after {
               margin-top: 0;
               rotate: -45deg;
            }
         }
      
         ul { padding: 0 0 rem(20) }

         .nav__link {
            img {
               left: $nav-link-x-padding;
               transform: translate(0, -50%);
            }
            .title {
               grid-template-columns: 1fr;
            }
         }
      }

   }

</style>