<template>
   <div class="empty-save-btns">
      <button type="submit" class="btn btn-success txt-cap">{{$t('lang_add')}}<i class="fas fa-plus mx-2"></i></button>
      <button type="button" class="btn-main-clr" data-empty-fields>{{$t('lang_empty_fields')}}<i class="fas fa-broom mx-2"></i> </button>
   </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
   setup () {
      
      onMounted(() => {
         el('[data-empty-fields]').addEventListener('click', ()=> {
            el('input,textarea', true).forEach(input => {
               if (input.hasAttribute('type')) {
                  if (input.getAttribute('type').trim() == 'radio' || input.getAttribute('type').trim() == 'checkbox') return;
               }

               if (input.hasAttribute('data-default-val-zero')) {
                  input.value = 0;
                  return;
               }

               if (input.hasAttribute('data-show-phone-count')) {
                  input.parentElement.querySelector('span').innerHTML = input.getAttribute('maxlength');
               }

               input.value = ''
            });
         })
      })

      return {}
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;
   .empty-save-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: rem(10);
   }
</style>