<template>
   <div class="table-responsive">
      <table :class="[tableClass, 'table']" v-table>
         <thead :class="headClass">
            <tr>
               <th v-if="selectDelete">
                  <div class="select-all">
                     <label for="chose-all"> {{ $t('lang_pick_all') }} </label>
                     <input class="form-check-input" type="checkbox" id="chose-all" v-select-all>
                  </div>
               </th>
               <slot name="head"></slot>
            </tr>
         </thead>
         <tbody>
            <slot name="body"></slot>
         </tbody>
      </table>
   </div>
</template>

<script>
export default {
   name:'Tables',
   props: {
      selectDelete: {
         type: Boolean,
         default: false,
      },

      tableClass: {
         type: String,
         default: 'table',
      },

      headClass: {
         type: String,
         default: null,
      }
   },
}
</script>